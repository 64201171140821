import { toggleElementByCheckboxEvent } from '../toggleElementByCheckboxEvent';
import { toggleElementByCheckboxEventReverse } from '../toggleElementByCheckboxEvent';

function toggleDreamPlanningEvent(toggleReverse) {
  const $checkbox = $('#dream-planing-checkbox');
  const $recurringForm = $('.payout-option-form');

  if(toggleReverse) {
    toggleElementByCheckboxEventReverse($checkbox, $recurringForm)
  } else {
    toggleElementByCheckboxEvent($checkbox, $recurringForm);
  }
}

function eventForPayoutOption() {
  let payoutOptionValue = $('#payout-option-selector').val();
  togglePayoutOption(payoutOptionValue);

  $('#payout-option-selector').on('change', ({ target }) => {
    payoutOptionValue = target.value;

    togglePayoutOption(payoutOptionValue);
  });
}

function togglePayoutOption(payoutOption) {
  const $lumpSumPayoutOptionSelector = $('#lump-sum-payout-option');
  const $recurringPayoutOption = $('#recurring-payout-option');

  if (payoutOption == 'lump_sum_payout') {
    $lumpSumPayoutOptionSelector.show();
    $recurringPayoutOption.hide();
  } else if (payoutOption == 'recurring_payout') {
    $lumpSumPayoutOptionSelector.hide();
    $recurringPayoutOption.show();
  } else {
    $lumpSumPayoutOptionSelector.hide();
    $recurringPayoutOption.hide();
  }
}

export const handleToggleDreamPlanning = (listID, toggleReverse = false) => {
  $('body').on('modal:afterSetContent', `${listID.join(',')}`, () => {
    toggleDreamPlanningEvent(toggleReverse);
    eventForPayoutOption();
  });
}
