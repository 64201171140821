import Raicon from 'raicon';
import ImageCropper from '../../shared/imageCropper'
import { togglePassword } from '../../clients/shared/togglePassword';

$(document).on('click', '.toggle-password', ({ target }) => {
  const $passwordInput = $(target).parent().find(':input');
  if ($passwordInput.attr('type') === 'password') {
    $passwordInput.attr('type', 'text');
    $(target).addClass('show-pass');
    $(target).removeClass('hidden-pass');
  } else {
    $passwordInput.attr('type', 'password');
    $(target).addClass('hidden-pass');
    $(target).removeClass('show-pass');
  }
});

class ConsultantSettingsController {
  constructor() {
    this.imageSelector = '.image-file-input';
    this.imagePreviewSelector = '.preview-input-file';
  }

  edit() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.checkCorrectPasswordLength();
    this.eventForRegenerateMerlinApiKey();
    this.eventForSubscribeWhatsappNumber();
    this.eventForUploadFileFcBrand();
    this.handleImageChange();
    this.handleRemoveAttachement();
    this.handleCountingCharacters();
    togglePassword();
  }

  updatePassword() {
    this.checkCorrectPasswordLength();
  }

  checkCorrectPasswordLength() {
    const atLeastUpperCaseRegex = /.*?[A-Z]/;
    const atLeastNumRegex = /.*?[0-9]/;
    const atLeastSpecialRegex = /.*?[#?!@$%^&*-._\(\)]/;

    $('#user_password').on('keyup', function () {
      const $this = $(this);
      $("#leastCharacter").toggleClass('checked', $this.val().length >= 8);
      $("#leastCapital").toggleClass('checked', $this.val().match(atLeastUpperCaseRegex) !== null);
      $("#leastNumber").toggleClass('checked', $this.val().match(atLeastNumRegex) !== null);
      $("#leastSymbol").toggleClass('checked', $this.val().match(atLeastSpecialRegex) !== null);
    });
  }

  eventForRegenerateMerlinApiKey() {
    $('#regenerate-merlin-api-key-button').on('click', () => {
      $.post(
        Routes.regenerate_merlin_api_key_consultant_settings_path(),
        data => {
          const apiKey = data.api_key;
          $('#merlin-api-key-input').val(apiKey);
        }
      )
    })

    $('#copy-merlin-api-key-button').on('click', ({ target }) => {
      const $tempInput = $("<textarea>");
      $("body").append($tempInput);
      $tempInput.val($('#merlin-api-key-input').val()).trigger('select');
      document.execCommand("copy");
      $tempInput.remove();
      alert('Copied to clipboard!');
    })
  }

  eventForSubscribeWhatsappNumber() {
    $('#subscribe-whatsapp-information-button').on('click', () => {
      const phoneNumber = $('#phone-number-input').val();
      const countryCode = $('#country-code-input').val();
      const agreeReceiveNotifications = $('#agree-receive-notifications-input').is(':checked') ? '1' : '0';

      $.post(
        Routes.subscribe_whatsapp_number_consultant_settings_path(),
        {
          phone_number: phoneNumber,
          country_code: countryCode,
          agree_receive_notifications: agreeReceiveNotifications
        }
      )
    })
  }

  eventForUploadFileFcBrand() {
    $('.upload-file-btn').on('click', ({ target }) => {
      $(target).closest('.mt-2').find('input[type="file"]').trigger('click');
    });
  }

  handleImageChange() {
    $(this.imageSelector).on('change', ({ target }) => {
      const file = target.files[0];
      if (!file) return;

      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).removeClass('d-none');
      $(target).closest('.file-input-wrapper').find(this.imagePreviewSelector).attr('src', URL.createObjectURL(file));
    });
  }

  handleRemoveAttachement() {
    $('.remove-attachment').on('click', ({ target }) => {
      const targetSelector = $(target);

      if (confirm("It's will delete your resource intermediate without submit. Are you sure?")) {
        if (typeof targetSelector.data('attachment-id') == 'undefined') {
          targetSelector.closest('.file-input-wrapper').find(this.imagePreviewSelector).addClass('d-none');
          targetSelector.closest('.file-input-wrapper').find(this.imageSelector).val('');
          return;
        }

        const blobId = targetSelector.data('attachment-id');
        $.ajax({
          url: Routes.destroy_signed_upload_file_path(blobId),
          type: 'DELETE',
          dataType: 'json',
          success: function () {
            window.location.reload();
          },
        });
      }
    });
  }

  handleCountingCharacters() {
    const $input = $('#bio-input');
    const $counter = $('#bio-counter');
    const maxLength = $input.attr('maxlength');

    $input.on('input', function () {
      var inputLength = $(this).val().length;
      var remaining = maxLength - inputLength;
      $counter.text(remaining + ' characters remaining');
    });
    $input.trigger('input');
  }

}

Raicon.register('consultant/settings', ConsultantSettingsController);
