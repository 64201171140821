export function changePrWhenNationalityIsSingapore() {
  changePrWhenNationIsSingaporean();
  toggleCorrespondencePr('.client_singapore_pr_since');
}

const changePrWhenNationIsSingaporean = () => {
  $(document).on('change', '#client_nationality', () => {
    const singaporePr = 'client_singapore_pr';
    const value = $('#client_nationality :selected').val();

    if (value === 'Singapore Citizen') {
      $(`.${singaporePr}`).addClass('d-none');
      $(`.${singaporePr}_since`).addClass('d-none');

      $('input[name="client[singapore_pr]"][value=false]').prop('checked', true);
      $('#client_singapore_pr_since').val('');
    } else {
      $(`.${singaporePr}`).removeClass('d-none');
    }
  });
};

const toggleCorrespondencePr = (singaporePrSince) => {
  const $checkboxPr = $(':radio[name="client[singapore_pr]"]');

  if ($checkboxPr.filter(':checked').val() === 'true') {
    $(`${singaporePrSince}`).removeClass('d-none');
  }

  $(document).on('change', ':radio[name="client[singapore_pr]"]', function() {
    if ($(this).filter(':checked').val() === 'true') {
      $(`${singaporePrSince}`).removeClass('d-none');
    } else {
      $(`${singaporePrSince}`).addClass('d-none');
      $('#client_singapore_pr_since').val('');
    }
  });
};

const toggleCorrespondencePrBaseOnNationality = () => {
  $('#client_nationality').on('change', () => {
    const value = $('#client_nationality :selected').val();
    let options;

    switch (value) {
      case 'Singapore Citizen':
        options = [{ value: 'Malaysia PR', text: 'Malaysia PR' }];
        break;
      case 'Malaysian':
        options = [{ value: 'Singapore PR', text: 'Singapore PR' }];
        break;
      default:
        options = [
          { value: 'Singapore PR', text: 'Singapore PR' },
          { value: 'Malaysia PR', text: 'Malaysia PR' },
        ];
        break;
    }

    updatePermanentResidentOptions(options);
  });
};

const updatePermanentResidentOptions = (options) => {
  const select = $('#permanent-resident');
  select.empty();
  options.forEach((option) => {
    select.append(new Option(option.text, option.value));
  });

  $('#permanent-resident').trigger('change');
};

const togglePrFieldsBasedOnResidency = () => {
  const toggleFields = () => {
    const value = $('#permanent-resident :selected').val();

    switch (value) {
      case 'Singapore PR':
        $('#singapore-pr').show();
        $('#singapore-pr-since').show();
        $('#malaysia-pr').hide();
        $('#malaysia-pr-since').hide();
        break;
      case 'Malaysia PR':
        $('#malaysia-pr').show();
        $('#malaysia-pr-since').show();
        $('#singapore-pr').hide();
        $('#singapore-pr-since').hide();
        break;
      default:
        $('#singapore-pr').hide();
        $('#singapore-pr-since').hide();
        $('#malaysia-pr').hide();
        $('#malaysia-pr-since').hide();
        break;
    }
  };

  // Run the function when the form is first initialized
  toggleFields();

  // Run the function when the value of #permanent-resident changes
  $('#permanent-resident').on('change', toggleFields);
};
