import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import { displayOtherOptionInputField, handleWhenSelectOthersValue } from '../shared/toggleHandler';

export default class OverviewBioModalForm {
  constructor() {
    this.buttonEditBioSelector = '.edit-bio';
    this.checkboxSameAsResidentalSelector = '.same-as-residential-address';
    this.correspondenceFormSelector = '.correspondence-address-form';
    this.sourceSelectSelector = '#client-source-select';
    this.sourceOptionInputSelector = '.source-other-option-input';
    this.occupationSelectSelector = '#client-occupation-select';
    this.occupationOptionInputSelector = '.occupation-other-option-input';
    this.openBiodataSelector = '#open-biodata-form';
    this.biodataFormSelector = '#biodata-form';
    this.dobInputSelector = '#dob';
    this.ageShowSelector = '.input-age';
    this.singaporePrSelector = ':radio[name="client[singapore_pr]"]';
    this.singaporePrSince = '.client_singapore_pr_since';
  }

  setup() {
    this.addEditButtonTriggerOpenModalEvents();
  }

  addEditButtonTriggerOpenModalEvents() {
    $(this.buttonEditBioSelector).on('modal:afterSetContent', () => {
      displayOtherOptionInputField($(this.sourceSelectSelector), $(this.sourceOptionInputSelector));
      handleWhenSelectOthersValue($(this.sourceSelectSelector), $(this.sourceOptionInputSelector));
      displayOtherOptionInputField($(this.occupationSelectSelector), $(this.occupationOptionInputSelector));
      handleWhenSelectOthersValue($(this.occupationSelectSelector), $(this.occupationOptionInputSelector));
      this.changePrWhenNationIsSingaporean();
    });

    $('body').on('modal:afterSetContent', `${this.openBiodataSelector}, ${this.biodataFormSelector}`, () => {
      displayOtherOptionInputField($(this.sourceSelectSelector), $(this.sourceOptionInputSelector));
      calculateAgeByInput($(this.dobInputSelector), $(this.ageShowSelector));
      this.toggleCorrespondenceAddressEvent();
      this.toggleCorrespondencePr(this.singaporePrSince);
      this.toggleCorrespondencePrBaseOnNationality();
      this.handleChangeActiveTab();
    });
  }

  toggleCorrespondencePrBaseOnNationality() {
    $('#client_nationality').on('change', () => {
      const value = $('#client_nationality :selected').val();
      if (value == 'Singapore Citizen') {
        $('.client_singapore_pr').addClass('d-none');
        $(this.singaporePrSince).addClass('d-none');
      } else {
        $('.client_singapore_pr').removeClass('d-none');
      }
    });
  }

  changePrWhenNationIsSingaporean() {
    $('#client_nationality').on('change', () => {
      const value = $('#client_nationality :selected').val();
      if (value == 'Singapore Citizen') {
        $('input[name="client[singapore_pr]"][value=false]').prop('checked', true);
      }
    });
  }

  toggleCorrespondenceAddressEvent() {
    const $checkbox = $(this.checkboxSameAsResidentalSelector);
    const $correspondenceForm = $(this.correspondenceFormSelector);

    if ($checkbox.is(':checked')) {
      $correspondenceForm.hide();
    }
    $checkbox.on('change', function () {
      if (this.checked) {
        $correspondenceForm.hide();
      } else {
        $correspondenceForm.show();
      }
    });
  }

  toggleCorrespondencePr(singaporePrSince) {
    const $checkboxPr = $(this.singaporePrSelector);

    if ($checkboxPr.filter(':checked').val() == 'true') {
      $(`${singaporePrSince}`).removeClass('d-none');
    }

    $checkboxPr.on('change', function  () {
      if ($(this).filter(':checked').val() == 'true') {
        $(`${singaporePrSince}`).removeClass('d-none');
      } else {
        $(`${singaporePrSince}`).addClass('d-none');
      }
    })
  }

  handleChangeActiveTab() {
    $('#overview-tab .nav-link').on('click', (e) => {
      const activeTab = $(e.target).data('tab-name');
      let formAction = $(this.biodataFormSelector).attr('action');
      formAction = `${formAction.split('?')[0]}?active_tab=${activeTab}`;

      $(this.biodataFormSelector).attr('action', formAction);
    })
  }
}
