import { getUrlParamValueByName } from '@/helpers';
import FamilyMemberForm from '../family/familyMemberForm';

export default class AddFamilyMemberAction {
  setup(modalSelector) {
    const modalElement = $(modalSelector);
    let clientId = $('body').data('client-id') || modalElement.data('client-id');
    const backUrl = modalElement.data('back-url');
    let params;
    let addMemberButtons = [];

    switch (modalSelector) {
      case '.dream-modal':
        let dreamFormId = modalElement.data('dream-form-id');
        let dreamType = modalElement.data('dream-type');

        addMemberButtons.push($('.shared-dream-family-member-list').find('.form-check').last());
        let memberId = getUrlParamValueByName('member_id');
        let isCreateFromSharedIndex = false;

        if (memberId) {
          isCreateFromSharedIndex = true;
        }

        params = {
          client_id: clientId,
          create_member_from_shared_dream: true,
          is_shared_index: isCreateFromSharedIndex,
          dream_form_id: dreamFormId,
          dream_type: dreamType,
          back_url: backUrl,
        };
        break;
      case '.insurance-modal':
        addMemberButtons.push($('#insurance-person-list').find('.form-check').last());
        addMemberButtons.push($('#payor-list').find('.form-check').last());
        params = {
          client_id: clientId,
          back_url: backUrl,
          create_member_from_insurance_policy: true,
        };
        break;
      case '.networth-modal':
        if($('#insurance-person-list').find('.form-check').length == 1) return;
        addMemberButtons.push($('#insurance-person-list').find('.form-check').last());
        params = {
          client_id: clientId,
          back_url: backUrl,
          create_member_from_networth: true,
        };
        break;
      case '.emergency-network-modal':
        addMemberButtons.push($('.emergency_network_family_member_id').find('.form-check').last());

        params = {
          client_id: clientId,
          back_url: backUrl,
          create_member_from_emergency_network: true,
        }
        break;
      case '.insurance-mobile':
        if (!clientId) {
          clientId = modalElement.data('client-id');
        }
        addMemberButtons.push($('#insurance-person-list').find('.form-check').last());
        addMemberButtons.push($('#payor-list').find('.form-check').last());
        params = {
          client_id: clientId,
          back_url: backUrl,
          create_member_from_insurance_policy: true,
        };
        break;
    }

    addMemberButtons.forEach((addMemberButton) => {
      addMemberButton.replaceWith(`
      <a data-modal="true" data-type="html" data-remote="true" id="open-new-member" href=${Routes.new_consultant_client_family_path(
        params,
      )}>+ member</a>
    `);
    });

    new FamilyMemberForm().setup();
  }
}
