import TaskForm from '@/controllers/consultant/tasks/formTask';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';
import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { toggleTdTable } from '@/layouts/application/utils/multipleSelectTable';
import { securityText } from '@/layouts/application/utils/securityText';
import Raicon from 'raicon';
import AppointmentForm from '../planners/appointmentForm';
import { fillDataForDataContactWithSecurity } from '../shared/fillDataForAddContact';
import { changePrWhenNationalityIsSingapore } from '../shared/handleNationalitySelectChange';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';
import FilterForm from '../clients/filterForm';
import { handleChangeContactType } from '../shared/handleChangeContactType';
import ImportPeopleForm from '@/layouts/application/utils/dropzone/importPeopleForm';
import ImportContactForm from '@/layouts/application/utils/dropzone/importContactForm';
import ImportInsurancePoliciesForm from '@/layouts/application/utils/dropzone/importInsurancePoliciesForm';
import ContactForm from '../clients/contactForm';

class ConsultantAllPeopleController {
  index() {
    this.checkboxSelected();
    toggleTdTable();
    clearInputNumberOnFocus();
    this.initSecurity();
    this.addTriggerOpenModalEvents();
    this.hiddenSearchDropSelect2('#labels_filter');
    this.eventForToggleContactListTable();
    this.eventForToggleSecurityTable();
    this.setupTaskForm();
    this.setupAppointmentForm();
    this.setupFilterForm();
    this.setupContactForm();
    this.initDropZone();

    copyExecuted('#copy-pre-find-fact-link', '#pre-fact-find-link');
    copyExecuted('#copy-post-fna-link', '#post-fna-link');
    copyExecuted('#copy-testimonial-link', '#testimonial-link');
    copyExecuted('#copy-post-quiz-link', '#post-quiz-link');
    copyExecuted('#copy-post-quiz-career-link', '#post-quiz-career-link');
    copyExecuted('#copy-post-quiz-friendship-compatibility-link', '#post-quiz-friendship-compatibility-link');
    copyExecuted('#copy-pay-forward-link', '#pay-forward-link');
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link');
    copyExecuted('#copy-finding-merlin-link', '#finding-merlin-link');
    copyExecuted('#copy-client-link', '#client-portal-link');
    fillDataForDataContactWithSecurity();
    handleMoneyField();
    handleTrackClickBanner();
    handleChangeContactType();
  }

  initDropZone() {
    new ImportPeopleForm().setup();
    new ImportContactForm().setup();
    new ImportInsurancePoliciesForm().setup();
  }

  initSecurity() {
    if ($('.all-people-page').data('is-security') === 'true' || $('.all-people-page').data('is-security')) {
      securityText('.country-code');
      securityText('.email-field', 'email');
      securityText('.mobile-field');
    }
  }

  eventForToggleSecurityTable() {
    $(document).on('click', '#hide-security-table-button, #show-security-table-button', function() {
      const securityButton = $(this);
      securityButton.prop('disabled', true);

      $.post(Routes.toggle_security_table_consultant_clients_path({ type: 'all' }), function() {
        window.location.reload();
      }).fail(function() {
        securityButton.prop('disabled', false);
      });
    });
  }

  eventForToggleContactListTable() {
    $('body').on('click', '#hide-contact-list-button', () => {
      $.post(Routes.hide_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').addClass('d-none');
        $('#hide-contact-list-content').removeClass('d-none');
      });
    });

    $('body').on('click', '#show-contact-list-button', () => {
      $.post(Routes.show_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').removeClass('d-none');
        $('#hide-contact-list-content').addClass('d-none');
      });
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#create-tags', () => {
      // this.hiddenSearchDropSelect2('#labels_labels');
      this.addIdsToAddLabelsAndUpdateRating();
    });

    $('body').on('click', '#bulk-update-rating', () => {
      this.addIdsToAddLabelsAndUpdateRating();
    });

    $('body').on('click', '#bulk-update-lead', () => {
      this.addIdsToAddLabelsAndUpdateContentType();
    });

    $('body').on('click', '#new-referee-to-contact-button', () => {
      changePrWhenNationalityIsSingapore();
    });
  }

  addIdsToAddLabelsAndUpdateRating() {
    const selectBox = $('.select-box:checked');
    const labelInputIds = $('#create-labels-client-ids');
    const ratingInputIds = $('#bulk-update-rating-client-ids');
    let ids = [];

    let invalidType = false;

    selectBox.each(function () {
      const value = $(this).data('value');
      const type = $(this).data('type');
      if ('client' === type) ids.push(value);
      else invalidType = true;
    });

    labelInputIds.val(ids);
    ratingInputIds.val(ids);

    // invalidType should be disabled form submit button or not?
  }

  addIdsToAddLabelsAndUpdateContentType() {
    const selectBox = $('.select-box:checked');
    const labelInputIds = $('#bulk-update-lead-client-ids');
    const ratingInputIds = $('#bulk-update-lead-modal');
    let ids = [];
    let invalidType = false;
    selectBox.each(function () {
      const value = $(this).data('value');
      const type = $(this).data('type');
      if ('client' === type) ids.push(value);
      else invalidType = true;
    });

    labelInputIds.val(ids);
    ratingInputIds.val(ids);

    // invalidType should be disabled form submit button or not?
  }

  hiddenSearchDropSelect2(id) {
    $(id)
      .select2({
        tags: true,
        allowClear: false,
        tokenSeparators: [';', '	', ' '],
      })
      .on('select2:open', function (e) {
        $('.select2-container--open .select2-dropdown--below').css('display', 'none');
      });
  }

  show() {
    console.log('Script for `app/views/consultant/clients/show.slim`');
  }

  setupTaskForm() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task', () => {
      new TaskForm().setup();
    });
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }

  setupContactForm() {
    $('body').on('modal:afterSetContent', '#open-new-contract, #new-contact-form, #edit-referee-form, #edit-contact-button', () => {
      changePrWhenNationalityIsSingapore();
      new ContactForm().setup();
    });
  }

  checkboxSelected() {
    const selectBox = $('.select-box');
    const maxSelectBox = selectBox.length;
    const btnDestroy = $('#btn-destroy-clients');
    const baseUrl = btnDestroy.attr('href');

    let client_ids = [];
    let referee_ids = [];
    let countChecked = 0;

    $('#main-select-box').change(function () {
      countChecked = 0;
      const $this = $(this);
      selectBox.prop('checked', $this.is(':checked')).change();
    });

    $('body').on('change', '.select-box', function () {
      const $this = $(this);
      const value = $this.data('value');

      if ($this.is(':checked')) {
        $this.parents('tr').addClass('bg-light-blue');
        const type = $this.data('type');
        if ('client' === type) {
          client_ids.push(value);
          countChecked++;
        }
        if ('referee' === type) {
          referee_ids.push(value);
          countChecked++;
        }

        if (countChecked == maxSelectBox) {
          $('#main-select-box').prop('checked', true);
        }
      } else {
        $this.parents('tr').removeClass('bg-light-blue');
        client_ids = client_ids.filter((i) => i !== value);
        referee_ids = referee_ids.filter((i) => i !== value);

        if (countChecked > 0) {
          countChecked--;
        }

        $('#main-select-box').prop('checked', false);
      }

      const batchDeleteUrl = `${baseUrl.replace(/\/$/, '')}${client_ids.length ? client_ids : -1}/${referee_ids.length ? referee_ids : -1
        }`;
      btnDestroy.attr('href', batchDeleteUrl);
      $('#nums-checked').text(`${countChecked} Contact${countChecked > 1 ? 's' : ''} selected`);
    });
  }

  setupFilterForm() {
    $('#show-filter-modal').on('click', () => {
      if ($('#filter-modal').data('setup-finished') != true) {
        new FilterForm().setup();
      }
    });
  }
}

Raicon.register('consultant/allPeople', ConsultantAllPeopleController);
