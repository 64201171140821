import TaskForm from '@/controllers/consultant/tasks/formTask';
import calculateAgeByInput from '@/layouts/application/utils/calculateAgeByInput';
import changeParamenterByTab from '@/layouts/application/utils/changeParameterByTab';
import clearInputNumberOnFocus from '@/layouts/application/utils/clearInputNumber';
import handleMoneyField from '@/layouts/application/utils/handleMoneyField';
import { toggleTdTable } from '@/layouts/application/utils/multipleSelectTable';
import { securityText } from '@/layouts/application/utils/securityText';
import Raicon from 'raicon';
import AppointmentForm from '../planners/appointmentForm';
import { fillDataForDataContactWithSecurity } from '../shared/fillDataForAddContact';
import { handleTrackClickBanner } from '../shared/handleTrackClickBanner';

class RecruitmentsController {
  index() {
    this.checkboxSelected();
    toggleTdTable();
    clearInputNumberOnFocus();
    this.initSecurity();
    this.addTriggerOpenModalEvents();
    this.hiddenSearchDropSelect2('#labels_filter');
    this.eventForToggleContactListTable();
    this.eventForToggleSecurityTable();
    this.setupTaskForm();
    this.setupAppointmentForm();
    changeParamenterByTab('people', 'people_tab', true);
    fillDataForDataContactWithSecurity();
    handleMoneyField();
    handleTrackClickBanner();
  }

  initSecurity() {
    if ($('.all-people-page').data('is-security') === 'true' || $('.all-people-page').data('is-security')) {
      securityText('.country-code');
      securityText('.email-field', 'email');
      securityText('.mobile-field');
    }
  }

  eventForToggleSecurityTable() {
     $(document).on('click', '#hide-security-table-button, #show-security-table-button', function() {
       const securityButton = $(this);
       securityButton.prop('disabled', true);

      $.post(Routes.toggle_security_table_consultant_clients_path({ type: 'recruitments' }), function() {
        window.location.reload();
      }).fail(function() {
        securityButton.prop('disabled', false);
      });
    });
  }

  eventForToggleContactListTable() {
    $('#hide-contact-list-button').on('click', () => {
      $.post(Routes.hide_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').addClass('d-none');
        $('#hide-contact-list-content').removeClass('d-none');
      });
    });

    $('#show-contact-list-button').on('click', () => {
      $.post(Routes.show_contact_list_consultant_clients_path(), () => {
        $('#show-contact-list-content').removeClass('d-none');
        $('#hide-contact-list-content').addClass('d-none');
      });
    });
  }

  addTriggerOpenModalEvents() {
    $('body').on('modal:afterSetContent', '#open-new-contract, #new-contact-form', () => {
      calculateAgeByInput('#dob', '.input-age');
      this.updateEmailInvalidMessage();
    });
  }

  hiddenSearchDropSelect2(id) {
    $(id)
      .select2({
        tags: true,
        allowClear: false,
        tokenSeparators: [';', '	', ' '],
      })
      .on('select2:open', function (e) {
        $('.select2-container--open .select2-dropdown--below').css('display', 'none');
      });
  }

  updateEmailInvalidMessage() {
    const invalidSelector = $('div.email .invalid-feedback');
    if ($(invalidSelector).length > 0) {
      const message = $(invalidSelector).html();
      if (message.includes('is invalid')) {
        $(invalidSelector).html('Please enter your email address in format: yourname@example.com');
      } else {
        $(invalidSelector).html('Email has already been taken or User already exist and is tagged under someone else');
      }
    }
  }

  setupTaskForm() {
    $('body').on('modal:afterSetContent', '#open-new-tasks, #new_task', () => {
      new TaskForm().setup();
    });
  }

  setupAppointmentForm() {
    $('body').on('modal:afterSetContent', '#new-appointment-button, #appointment-form', () => {
      new AppointmentForm().setup();
    });
  }

  checkboxSelected() {
    const selectBox = $('.select-box');
    const maxSelectBox = selectBox.length;
    const btnDestroy = $('#btn-destroy-clients');
    const baseUrl = btnDestroy.attr('href');

    let client_ids = [];
    let referee_ids = [];
    let countChecked = 0;

    $('#main-select-box').change(function () {
      countChecked = 0;
      const $this = $(this);
      selectBox.prop('checked', $this.is(':checked')).change();
    });

    $('body').on('change', '.select-box', function () {
      const $this = $(this);
      const value = $this.data('value');

      if ($this.is(':checked')) {
        $this.parents('tr').addClass('bg-light-blue');
        const type = $this.data('type');
        if ('client' === type) {
          client_ids.push(value);
          countChecked++;
        }
        if ('referee' === type) {
          referee_ids.push(value);
          countChecked++;
        }

        if (countChecked == maxSelectBox) {
          $('#main-select-box').prop('checked', true);
        }
      } else {
        $this.parents('tr').removeClass('bg-light-blue');
        client_ids = client_ids.filter((i) => i !== value);
        referee_ids = referee_ids.filter((i) => i !== value);

        if (countChecked > 0) {
          countChecked--;
        }

        $('#main-select-box').prop('checked', false);
      }

      const batchDeleteUrl = `${baseUrl.replace(/\/$/, '')}${client_ids.length ? client_ids : -1}/${referee_ids.length ? referee_ids : -1
        }`;
      btnDestroy.attr('href', batchDeleteUrl);
      $('#nums-checked').text(`${countChecked} Contact${countChecked > 1 ? 's' : ''} selected`);
    });
  }
}

Raicon.register('consultant/recruitments', RecruitmentsController);
