import Raicon from 'raicon';

import updateYearByInput from '@/layouts/application/utils/updateYearByInput';
import { add10Referees } from '../add10Referees';
import AddFamilyMemberAction from './addFamilyMemberAction';
import DreamAllocation from './dreamAllocationForm';
import DreamBusinessForm from './dreamBusinessForm';
import DreamCarForm from './dreamCarForm';
import DreamCustomForm from './dreamCustomForm';
import DreamEducationForm from './dreamEducationForm';
import DreamHavingChildForm from './dreamHavingChildForm';
import DreamIndexPage from './dreamIndexPage';
import DreamTravelForm from './dreamTravelForm';
import DreamUpdateProgress from './dreamUpdateProgressForm';
import DreamWeddingForm from './dreamWeddingForm';
import DreamHouseForm from './dreamHouseForm';
import RetirementTargetForm from './retirementTargetForm';
import SimulatedEventForm from './simulatedEventForm';
import GiftingCalculatorClientForm from '../clients/giftingCalculatorForm';
import { bulkDeleteItem } from '../shared/bulkDeleteItem';
import InsurancePolicyForm from '../insurances/insurancePolicyForm';
import { formatCurrency } from '@/layouts/application/utils/formats';
import { handleToggleDreamPlanning } from './handleToggleDreamPlanning';
import { handleHideSignSgd } from '../shared/handleHideSignSgd';

class ConsultantDreamsController {
  constructor() {
    this.withdrawalAgeSelector = '.withdrawal-age-input';
    this.withdrawalAmountSelector = '.withdrawal-amount';
    this.withdrawalFormSelector = '.simulated-event-cpf-withdrawal-form';
    this.estimatedTableSelector = '#estimated-cpf-table';
    this.withdrawalAmountErrorSelector = '.withdrawal-amount-error';
    this.submitUpdateButtonSelector = '#update-event-button';
    this.submitCreatebuttonSelector = '#create-event-button';
    this.editSimulatedEventSelector = '#edit-simulated-event';
    this.newSimulatedEventSelector = '#new-simulated-event';
    this.editSimulatedEventFormSelector = '#edit-simulated-event-form';
    this.newSimulatedEventFormSelector = '#new-simulated-event-form';
    this.propertyRemainLeaseAfter95 = '#property-remaining-lease-after-95';
  }

  index() {
    this.setupDreams();
    this.setupRetirementTarget();
    add10Referees('#open-new-referee', '#multiple-referee-form');
    this.toggleDreamRightContent();
    this.addEventsForDreamMerlinInsigh();
    this.handleJobLossSubForm();
    this.setupGiftingCalculatorForm();
    this.handleDreamItemTooltip();
    this.setupInsurances();
    this.handleChangeCpfWithdrawal();
    handleHideSignSgd('#currency-select', '.property-currency');
    handleToggleDreamPlanning(['#add-policy-button', '#edit-policy-button', '#insurance-policy-form']);
    bulkDeleteItem('#bulk-delete-dream', '.dream-checkbox');
  }

  setupGiftingCalculatorForm() {
    new GiftingCalculatorClientForm().setup();
  }

  dreamRetirementIndex() {
    this.setupDreams();
    this.setupRetirementTarget();
  }

  setupDreams() {
    this.addDreamModalEventListeners();
    new DreamIndexPage().setup();
  }

  setupRetirementTarget() {
    $('body').on('modal:afterSetContent', '#open-retirement-target-modal', () => {
      new RetirementTargetForm().setup();
    });
  }

  addEventsForDreamMerlinInsigh() {
    $('#copy-blue-sky-escapes-details').on('click', () => {
      const $tempInput = $('<textarea>');
      $('body').append($tempInput);
      $tempInput
        .val(
          'Craft unique travel journeys with Blue Sky Escapes to unlock and uncover extraordinary experiences in Singapore and around the world. Leave an enquiry at  https://blueskyescapes.co/ and remember to include code BSExMerlin300 to enjoy S$300 off your first outbound journey!',
        )
        .trigger('select');
      document.execCommand('copy');
      $tempInput.remove();
      alert('Copied to clipboard!');
    });
  }

  addDreamModalEventListeners() {
    $('body').on(
      'modal:afterSetContent',
      '#new-share-dream-house-link, #new-share-dream-car-link, #new-share-dream-travel-link, #new-share-dream-wedding-link , #new-share-dream-education-link, #new-share-dream-start-business-link,  #new-share-dream-custom-link, #new-share-dream-having-child-link',
      () => {
        $('#share-dream-checkbox').trigger('click');
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-house-link, #new-share-dream-house-link, #open-edit-dream-house, #new-dream-house-form',
      () => {
        new DreamHouseForm().setup();
        new AddFamilyMemberAction().setup('.dream-modal');
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-car-link, #new-share-dream-car-link, #open-edit-dream-car, #new-dream-car-form',
      () => {
        new DreamCarForm().setup();
        new AddFamilyMemberAction().setup('.dream-modal');
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-travel-link, #new-share-dream-travel-link, #open-edit-dream-travel, #new-dream-travel-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamTravelForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-wedding-link, #new-share-dream-wedding-link, #open-edit-dream-wedding, #new-dream-wedding-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamWeddingForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-education-link, #new-share-dream-education-link, #open-edit-dream-education, #new-dream-education-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamEducationForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-start-business-link, #new-share-dream-start-business-link, #open-edit-dream-start-business, #new-dream-start-business-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamBusinessForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-custom-link, #new-share-dream-custom-link, #open-edit-dream-custom, #new-dream-custom-form, #new-dream-gifting-form, #new-dream-gifting-link, #open-edit-dream-gifting',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamCustomForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-retirement-link, #open-edit-dream-retirement, #new-dream-retirement-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamCustomForm().setup();
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#new-dream-having-child-link, #new-share-dream-having-child-link, #open-edit-dream-having-child, #new-dream-having-child-form',
      () => {
        new AddFamilyMemberAction().setup('.dream-modal');
        new DreamHavingChildForm().setup();
      },
    );

    $('body').on('modal:afterSetContent', '#change-allocation-btn', () => {
      new DreamAllocation().setup();
    });

    $('body').on('modal:afterSetContent', '#update-saving-progress-btn', () => {
      new DreamUpdateProgress().setup();
    });

    $('body').on(
      'modal:afterSetContent',
      '#new-simulated-event-death, #new-simulated-event-form, #edit-simulated-event-death, #edit-simulated-event-form',
      () => {
        new SimulatedEventForm().setup();
        updateYearByInput('#input-age-happen', '#year-happen-value');
      },
    );

    $('body').on(
      'modal:afterSetContent',
      '#edit-simulated-event, #edit-simulated-event-form, #new-simulated-event, #new-simulated-event-form',
      () => {
        updateYearByInput('#input-age-happen', '#year-happen-value');
      },
    );

    $('body').on('modal:afterSetContent', '#view-dream-detail-table, #retirement-dream-breakdown-detail-modal', () => {
      this.handleNestedDropdown();
    });
  }

  toggleDreamRightContent() {
    $('#slide-right').hide();
    $('#slide-left').show();

    $('.toggle-info').on('click', () => {
      if ($('.right-content').is(':hidden')) {
        $('.right-content').removeClass('mobile-d-none');
        $('.wrapper-right-content').removeClass('mobile-d-none');
        this.handleSlideIcon('left');
      } else {
        $('.right-content').addClass('mobile-d-none');
        $('.wrapper-right-content').addClass('mobile-d-none');
        this.handleSlideIcon('right');
      }
    });

    $('.hide-right-side').on('click', () => {
      $('.right-content').addClass('mobile-d-none');
      this.handleSlideIcon('right');
    });
  }

  handleSlideIcon(direction) {
    if (direction == 'left') {
      $('#toggle-right-content').css('left', 'calc(94vw - 300px)');
      $('#slide-left').hide();
      $('#slide-right').show();
    } else {
      $('#toggle-right-content').css('left', '94vw');
      $('#slide-right').hide();
      $('#slide-left').show();
    }
  }

  handleJobLossSubForm() {
    $('body').on(
      'modal:afterSetContent',
      '#edit-simulated-event, #edit-simulated-event-form, #new-simulated-event, #new-simulated-event-form',
      () => {
        this.handleToggle('#toggle-job-loss-sub-form', '#job-loss-sub-form');
        this.handleSyncAgeHappen();
        updateYearByInput('#age-happen-in-jobloss', '#year-happen-value-in-jobloss');

        $('#toggle-job-loss-sub-form').on('change', () => {
          this.handleToggle('#toggle-job-loss-sub-form', '#job-loss-sub-form');
        });

        $('#input-age-happen').on('change', () => {
          this.handleSyncAgeHappen();
          updateYearByInput('#age-happen-in-jobloss', '#year-happen-value-in-jobloss');
        });
      },
    );
  }

  handleToggle(checkingElement, toggleElement) {
    if ($(`${checkingElement}`).is(':checked')) {
      $(`${toggleElement}`).show();
    } else {
      $(`${toggleElement}`).hide();
    }
  }

  handleSyncAgeHappen() {
    const ageInputed = $('#input-age-happen').val();
    $('#age-happen-in-jobloss').val(ageInputed);
  }

  handleNestedDropdown() {
    $('.total-savings-dropdown').on('click', () => {
      $('.total-savings-element.show').collapse('hide');
      $('.total-savings-group.show').collapse('hide');
    });
  }

  handleDreamItemTooltip() {
    $('.dream--toggle__item').tooltip({
      html: true,
      placement: 'auto',
      trigger: 'hover',
    });
  }

  setupInsurances() {
    new InsurancePolicyForm().setup();
  }

  handleChangeCpfWithdrawal() {
    $('body').on(
      'modal:afterSetContent',
      `${this.editSimulatedEventSelector}, ${this.newSimulatedEventSelector}, ${this.newSimulatedEventFormSelector}, ${this.editSimulatedEventFormSelector}`,
      () => {
        this.handleValidateMaximumWithdrawal();
        this.calculateEstimatedCpfTable();
        this.handleChangePropertyRemainLeaseAfter95();
      },
    );

    $('body').on('change', `${this.withdrawalAgeSelector}`, () => {
      this.handleValidateMaximumWithdrawal();
      this.calculateEstimatedCpfTable();
      $(this.withdrawalAmountErrorSelector).hide();
    });

    $('body').on('change', `${this.withdrawalAmountSelector}`, () => {
      this.calculateEstimatedCpfTable();
      this.handleValidateMaximumWithdrawal();
    });
  }

  calculateEstimatedCpfTable() {
    const withdrawalAge = $(this.withdrawalAgeSelector).val();
    const clientId = $(this.withdrawalFormSelector).data('client-id');
    const withdrawalId = $(this.withdrawalFormSelector).data('withdrawal-id');
    const rawAmountValue = $(this.withdrawalAmountSelector).val();
    if (rawAmountValue) {
      const withdrawalAmount = rawAmountValue.replaceAll(',', '');
      if (withdrawalAge && withdrawalAmount) {
        $.ajax({
          url: Routes.estimated_cpf_table_consultant_client_simulated_events_path({
            client_id: clientId,
            age: withdrawalAge,
            amount: withdrawalAmount,
            withdrawal_id: withdrawalId,
          }),
          type: 'GET',
          success: (data) => {
            if($(this.withdrawalAmountErrorSelector).is(":hidden")) {
              $(this.estimatedTableSelector).html(data.html);
            }
          },
        });
      }
    }
  }

  handleValidateMaximumWithdrawal() {
    const rawAmountValue = $(this.withdrawalAmountSelector).val();
    if (rawAmountValue) {
      const withdrawalAmount = parseFloat(rawAmountValue.replaceAll(',', ''));
      const withdrawalAge = $(this.withdrawalAgeSelector).val();
      const clientId = $(this.withdrawalFormSelector).data('client-id');
      const withdrawalId = $(this.withdrawalFormSelector).data('withdrawal-id');
      let withdrawalYearlyLimit = $(this.withdrawalFormSelector).data('withdrawal-yearly-limit') || {};
      let checkingAgeValue = withdrawalYearlyLimit[withdrawalAge];
      if (checkingAgeValue) {
        this.validateMaximumWithdrawalAmount(withdrawalAmount, parseFloat(checkingAgeValue));
      } else if (withdrawalAge) {
        $.ajax({
          url: Routes.estimate_maxium_withdrawal_consultant_client_simulated_events_path({
            client_id: clientId,
            age: withdrawalAge,
            withdrawal_id: withdrawalId,
            amount: withdrawalAmount || 0
          }),
          type: 'GET',
          success: (data) => {
            withdrawalYearlyLimit[data[0]] = data[1];
            $(this.withdrawalFormSelector).data('withdrawal-yearly-limit', withdrawalYearlyLimit);
            this.validateMaximumWithdrawalAmount(withdrawalAmount, parseFloat(data[1]));
          },
        });
      }
    }
  }

  validateMaximumWithdrawalAmount(withdrawalAmount, maximumValue) {
    if (withdrawalAmount > maximumValue.toFixed(2)) {
      const exceedError = `Withdrawal at this year cannot be greater than ${formatCurrency(maximumValue.toFixed(2))}`;
      $(this.withdrawalAmountErrorSelector).text(exceedError);
      $(this.withdrawalAmountErrorSelector).show();
      $(this.submitUpdateButtonSelector).prop('disabled', true);
      $('#create-event-button').prop('disabled', true);
    } else {
      $(this.withdrawalAmountErrorSelector).hide();
      $(this.submitUpdateButtonSelector).prop('disabled', false);
      $('#create-event-button').prop('disabled', false);
    }
  }

  handleChangePropertyRemainLeaseAfter95() {
    const clientID = $('body').data('client-id');

    $(this.propertyRemainLeaseAfter95).on('change', (e) => {
      $.ajax({
        url: Routes.update_property_remain_lease_consultant_client_path(clientID, {
          property_remain_lease_at_least_95: $(this.propertyRemainLeaseAfter95).is(':checked')
        }),
        type: 'PUT',
        success: () => {
          this.clearCachedWithdrawalLimits();
          this.calculateEstimatedCpfTable();
          this.handleValidateMaximumWithdrawal();
        }
      })
    })
  }

  clearCachedWithdrawalLimits() {
    $(this.withdrawalFormSelector).data('withdrawal-yearly-limit', '');
  }
}

Raicon.register('consultant/dreams', ConsultantDreamsController);
