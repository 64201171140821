import Raicon from 'raicon';
import { formatCurrency, formatDate } from '@/layouts/application/utils/formats';
import handleMoneyField from '../../../layouts/application/utils/handleMoneyField';
import setupCpfLifeChart from '@/layouts/application/utils/charts/setupCpfLifeChart';
import handleSideMenu from '../shared/handleSideMenu';
import handleUploadAvatar from '../shared/handleUploadAvatar';
import handleLockForm from '../shared/handleLockForm';
import { getAge } from '@/layouts/application/utils/calculateAgeByInput';

class ClientsIncomesController {
  constructor() {
    this.formSelector = '.main-salary-income-form';
    this.incomeIsForeignerLabel = '#income_is_foreigner_label';
    this.forHasSingaporePr = '#for_has_singapore_pr';
    this.cpfContributionOnlyShow = '#cpf-contribution-only-show';
    this.incomeAmount = '#income_income_amount';
    this.incomeInterval = '#income_income_interval_type';
    this.employeeContribution = '#employee-contribution';
    this.employerContribution = '#employer-contribution';
    this.incomeEmployeeContribution = '#income_employee_contribution';
    this.incomeEmployerContribution = '#income_employer_contribution';
    this.chart = '#estimated_cpf_life_payout_modal_chart';
    this.chartInstance = null;
    this.incomeStartDate = '.income-start-date';
    this.incomeEndDate = '.income-end-date';
    this.incomeStartDateAge = '.income-start-date-age';
    this.incomeEndDateAge = '.income-end-date-age';
    this.clientId = $('#client-id-selector').data('value');
    this.isForeignerCheckbox = '#income_is_foreigner';
    this.isSelfEmployedSingaporeanCheckbox = '#income_is_self_employed_singaporean';
    this.hasSingaporePrCheckbox = '#income_has_singapore_pr';
    this.singaporePrSinceInput = '#singapore-pr-since-input';
  }

  beforeEach() {
    handleSideMenu();
    handleUploadAvatar();
  }

  new() {
    this.logicIncomeForm();
    handleMoneyField();
  }

  edit() {
    this.logicIncomeForm();
    handleMoneyField();
    handleLockForm(['#edit_income']);
  }

  showCpfLifeChart() {
    setupCpfLifeChart(true);
  }

  newIncomesSelection() {}

  quizCareerIncome() {
    $('#check_send_mail').on('click', () => {
      if ($('#check_send_mail').is(':checked')) {
        $('#send-mail-create-quiz-to-contact').removeClass('disabled');
      } else {
        $('#send-mail-create-quiz-to-contact').addClass('disabled');
      }
    });
  }

  logicIncomeForm() {
    this.clientId = $('#client-id-selector').data('value');
    if ($('#main-salary-income').hasClass('show')) {
      if ($('#income_salary_income_type').val() == 'salary') {
        this.checkboxLogicIncomeSalary();
        this.toggleCheckbox();
        this.loadCpfContribution();
      }
    }

    $('.main-income-type-selector #overview-tab .nav-link').on('click', () => {
      setTimeout(() => {
        if ($('#main-salary-income').hasClass('show')) {
          if ($('#income_salary_income_type').val() == 'salary') {
            this.checkboxLogicIncomeSalary();
            this.toggleCheckbox();
            this.loadCpfContribution();
          }
        } else {
          $(this.formSelector).off('input');
        }
      }, 165);
    });

    this.calculageAgeOnDateChange();
    this.calculateIncomeStartDateAge();
    this.calculateIncomeEndDateAge();
    this.autoFillCheckboxWhenChooseClientToSyncData();
  }

  checkboxLogicIncomeSalary() {
    const isForeigner = $(this.isForeignerCheckbox).is(':checked');
    const isSelfEmployedSingaporean = $(this.isSelfEmployedSingaporeanCheckbox).is(':checked');
    const hasSingaporePr = $(this.hasSingaporePrCheckbox).is(':checked');

    if (!isForeigner && !isSelfEmployedSingaporean && !hasSingaporePr) {
      $(this.incomeIsForeignerLabel).removeClass('important-rule');
      $(this.incomeIsForeignerLabel).removeClass('important-rule-before');
      $(this.cpfContributionOnlyShow).removeClass('d-none');
      $(this.forHasSingaporePr).css('display', 'none');
    } else if (hasSingaporePr && !isSelfEmployedSingaporean) {
      $(this.incomeIsForeignerLabel).addClass('important-rule');
      $(this.incomeIsForeignerLabel).addClass('important-rule-before');
      $(this.cpfContributionOnlyShow).removeClass('d-none');
      $(this.incomeIsForeignerLabel).css('display', 'block');
      $(this.forHasSingaporePr).css('display', 'block');
    } else if (isSelfEmployedSingaporean && !hasSingaporePr) {
      $(this.incomeIsForeignerLabel).addClass('important-rule');
      $(this.incomeIsForeignerLabel).addClass('important-rule-before');
      $(this.cpfContributionOnlyShow).addClass('d-none');
      $(this.forHasSingaporePr).css('display', 'none');
    } else if (isSelfEmployedSingaporean && hasSingaporePr) {
      $(this.incomeIsForeignerLabel).addClass('important-rule');
      $(this.incomeIsForeignerLabel).addClass('important-rule-before');
      $(this.cpfContributionOnlyShow).addClass('d-none');
      $(this.forHasSingaporePr).css('display', 'block');
    } else {
      $(this.incomeIsForeignerLabel).removeClass('important-rule');
      $(this.incomeIsForeignerLabel).removeClass('important-rule-before');
      $(this.cpfContributionOnlyShow).addClass('d-none');
      $(this.forHasSingaporePr).css('display', 'none');
    }
  }

  toggleCheckbox() {
    $('.main-salary-income-form').on('change', () => {
      this.checkboxLogicIncomeSalary();
    });
  }

  ajaxCallToCalculateCpfContribution() {
    const isForeigner = $(this.isForeignerCheckbox).is(':checked');
    const isSelfEmployedSingaporeanCheckbox = $(this.isSelfEmployedSingaporeanCheckbox).is(':checked');
    const hasSingaporePrCheckbox = $(this.hasSingaporePrCheckbox).is(':checked');
    const singaporePrSinceInput = $(this.singaporePrSinceInput).val();

    $.ajax({
      url: Routes.prepare_cpf_contribution_clients_incomes_path({
        client_id: this.clientId,
        income: $(this.incomeAmount).val(),
        income_interval: $(this.incomeInterval).find(':selected').val(),
        income_is_foreigner: isForeigner,
        income_is_self_employed_singaporean: isSelfEmployedSingaporeanCheckbox,
        income_has_singapore_pr: hasSingaporePrCheckbox,
        income_client_attributes_singapore_pr_since: singaporePrSinceInput,
      }),
      type: 'GET',
      success: (data) => {
        $(this.incomeEmployeeContribution).val(data['employee_contribution']);
        $(this.incomeEmployerContribution).val(data['employer_contribution']);
        $(this.employeeContribution).text(formatCurrency(data['employee_contribution']));
        $(this.employerContribution).text(formatCurrency(data['employer_contribution']));
      },
    });
  }

  loadCpfContribution() {
    if ($(this.incomeAmount).val() == '0') {
      $(this.employeeContribution).text(formatCurrency('0'));
      $(this.employerContribution).text(formatCurrency('0'));
    } else {
      this.ajaxCallToCalculateCpfContribution();
    }

    $(`${this.isForeignerCheckbox}, ${this.isSelfEmployedSingaporeanCheckbox}, ${this.hasSingaporePrCheckbox}`).on(
      'change',
      () => {
        this.ajaxCallToCalculateCpfContribution();
      },
    );

    $(this.singaporePrSinceInput).on('input', () => {
      this.ajaxCallToCalculateCpfContribution();
    });

    $(this.formSelector).on('input', `${this.incomeAmount}, ${this.incomeInterval}`, () => {
      this.ajaxCallToCalculateCpfContribution();
    });
  }

  calculageAgeOnDateChange() {
    $(this.incomeStartDate).on('change', ({ target }) => {
      const startDate = new Date(target.value);
      if (startDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), startDate);
      $(this.incomeStartDate).val(formatDate(startDate));
      $(this.incomeStartDateAge).html(age);
      if ($(this.incomeEndDate).val() == '') {
        const retirementDate = new Date($(this.incomeStartDate).data('retirement-date'));
        const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), retirementDate);
        $(this.incomeEndDate).val(formatDate(retirementDate));
        $(this.incomeEndDateAge).html(age);
      }
    });

    $(this.incomeEndDate).on('change', ({ target }) => {
      const endDate = new Date(target.value);
      if (endDate == 'Invalid Date') return;

      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), endDate);
      $(this.incomeEndDate).val(formatDate(endDate));
      $(this.incomeEndDateAge).html(age);
    });
  }

  calculateIncomeStartDateAge() {
    const startDate = $(this.incomeStartDate).val();
    if (startDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(startDate));
      $(this.incomeStartDateAge).html(age);
    }
  }

  calculateIncomeEndDateAge() {
    const endDate = $(this.incomeEndDate).val();
    if (endDate != '') {
      const age = getAge(new Date($('#date-of-birth-data').data('dob-data')), new Date(endDate));
      $(this.incomeEndDateAge).html(age);
    }
  }

  autoFillCheckboxWhenChooseClientToSyncData() {
    $('.sync-to-client-radio').on('change', ({ target }) => {
      const incomeIsForeigner = $(this.isForeignerCheckbox);
      const incomeIsSelfEmployedSingaporean = $(this.isSelfEmployedSingaporeanCheckbox);
      const incomeHasSingaporePr = $(this.hasSingaporePrCheckbox);
      const singaporePrSinceInput = $(this.singaporePrSinceInput);
      const singaporePrSinceText = $('.singapore-pr-since-wrapper .datepicker[type="text"]');
      const nationalitySelection = $('#nationality-selection');

      this.clientId = target.value ? target.value : $('#client-id-selector').data('value');

      $.get(Routes.auto_fill_checkbox_by_selected_client_clients_incomes_path({ client_id: this.clientId })).done(
        (data) => {
          incomeIsForeigner.prop('checked', data.is_foreigner);
          incomeIsSelfEmployedSingaporean.prop('checked', data.is_self_employed_singaporean);
          incomeHasSingaporePr.prop('checked', data.has_singapore_pr);
          nationalitySelection.val(data.nationality);

          const formatedDate = formatDate(new Date(data.singapore_pr_since));
          singaporePrSinceInput.val(formatedDate);
          singaporePrSinceText.val(formatedDate);

          this.checkboxLogicIncomeSalary();
          this.ajaxCallToCalculateCpfContribution();
        },
      );
    });
  }
}

Raicon.register('clients/incomes', ClientsIncomesController);
