import Raicon from 'raicon';
import ImageCropper from '../../shared/imageCropper'

class AdminUsersController {
  constructor() {
    this.imageSelector = '.image-file-input';
    this.imagePreviewSelector = '.preview-input-file';
    this.userRoleSelector = '.user-role';
    this.consultantRoleFieldsSelector = '.consultant-role-fields';
  }

  index() {
    this.toggleBulkOtp2FA();
    this.eventModifyOTP();
  }

  new() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.handleRemoveAttachement();
    this.toggleProfilePicture();
  }

  create() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.handleRemoveAttachement();
    this.toggleProfilePicture();
  }

  edit() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.handleRemoveAttachement();
  }

  update() {
    new ImageCropper('#profile-image-input', '#profile-pic-preview', '#profile-pic-signed-blob').setup();
    this.handleRemoveAttachement();
  }

  toggleBulkOtp2FA() {
    $('.select-box').on('change', (e) => {
      const _this = $(e.target);
      if (_this.is(':checked')) {
        $('#option-otp').removeClass('d-none');
      } else {
        if ($('.select-box:checked').length == 0) {
          $('#option-otp').addClass('d-none');
        }
      }
    });
  }

  handleRemoveAttachement() {
    $('.delete-profile-pic-button').on('click', ({ target }) => {
      const targetSelector = $(target);

      if (confirm("Are you sure delete?") == true) {
        if (typeof targetSelector.data('attachment-id') == 'undefined') {
          targetSelector.closest('.file-input-wrapper').find(this.imagePreviewSelector).addClass('d-none');
          targetSelector.closest('.file-input-wrapper').find(this.imageSelector).val('');
          targetSelector.closest('.delete-profile-pic-button').addClass('d-none');
          return;
        }

        const blobId = targetSelector.data('attachment-id');
        $.ajax({
          url: Routes.destroy_signed_admin_upload_file_path(blobId),
          type: 'DELETE',
          dataType: 'json',
          success: function() {
            window.location.reload();
          }
        });
      }
    });

    $('#profile-pic-input').on('change', function() {
      if (this.files && this.files[0]) {
        const reader = new FileReader();
        reader.onload = function(e) {
          $('#profile-pic-preview').attr('src', e.target.result).removeClass('d-none');
        };
        reader.readAsDataURL(this.files[0]);

        $('.delete-profile-pic-button[data-type="profile-pic"]').removeClass('d-none');
      }
    });

    $('#profile-banner-image').on('change', function() {
      if (this.files && this.files[0]) {
        const reader = new FileReader();
        reader.onload = function(e) {
          $('#profile-banner-preview').attr('src', e.target.result).removeClass('d-none');
        };
        reader.readAsDataURL(this.files[0]);

        $('.delete-profile-pic-button[data-type="profile-banner"]').removeClass('d-none');
      }
    });
  }

  eventModifyOTP() {
    $('#enable-link, #disable-link, #reset-link').on('click', (e) => {
      const _this = $(e.target);
      const oldUrl = _this.attr('href');

      let ids = $.map($('.select-box:checked'), (item) => {
        return $(item).data('value');
      });

      let newUrl = `${oldUrl}&ids=${ids.join(', ')}`;
      if (e.target.id == 'reset-link') {
        newUrl = `${oldUrl}?ids=${ids.join(', ')}`;
      }
      _this.attr('href', newUrl);
    });
  }

  toggleProfilePicture() {
    $(this.userRoleSelector).on('change', (event) => {
      if(event.target.value != 'consultant') {
        $(this.consultantRoleFieldsSelector).hide();
      } else {
        $(this.consultantRoleFieldsSelector).show();
      }
    })
  }
}

Raicon.register('admin/users', AdminUsersController);
