import { copyExecuted } from '@/layouts/application/utils/copyExecuted';
import { activeByCheckBox } from '@/layouts/application/utils/activeButtonWithCondition'
import Raicon from 'raicon';

class IpreciatesController {
  new(){
    activeByCheckBox('#ipreciate-submit-btn')
  }

  create(){
    activeByCheckBox('#ipreciate-submit-btn')
  }

  thankYou() {
    copyExecuted('#copy-ipreciate-link', '#ipreciate-link')
  }
}
Raicon.register('ipreciates', IpreciatesController);
