// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';

import * as Routes from 'routes';
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'

window.Routes = Routes;

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// Using for jquery in js.slim
window.jQuery = window.$ = require('jquery');
window.Cropper = Cropper

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import '@/layouts/application';
import '@/layouts/admin_v2';
import '@/layouts/guest';
import '@/layouts/landing';
import '@/controllers/consultant';
import '@/controllers/client';
import '@/controllers/users';
import '@/controllers/request_access';
import '@/controllers/admin';
import '@/controllers/admin_v2';
import '@/controllers/guest';
import '@/controllers/landing';
import '@/controllers/redbrick';
import '@/controllers/clients';

require('trix');
require('@rails/actiontext');
import 'trix/dist/trix.css';
